<template>
    <div class="step">
        <step-header :step-title="trans.step.titles.stepTitle" :catch-phrase="trans.step.titles.catchPhrase"/>

        <div class="form">
            <!-- 1. Bottles quantity -->
            <div class="form__pre-group">
                <div class="util--flex">
                    <h4>{{ trans.step.bottles.title }}</h4>
                    <tooltip v-if="trans.step.bottles.tooltip.isEnable" :title="trans.step.bottles.tooltip.title" :content="trans.step.bottles.tooltip.description"/>
                </div>
                <span class="form__pre-group__info">{{ trans.step.bottles.clarification }}</span>
            </div>
            <div class="form__group" :class="{'form__error': validation.errors.bottlesQuantity}">
                <!-- Available types -->
                <div class="form__selectable">
                    <div v-for="bottlesQuantityItem in availableBottlesQuantity" :key="bottlesQuantityItem.id" class="form__selectable-item">
                        <label :for="'bottlesQuantity_'+bottlesQuantityItem.idStr">
                            <input :id="'bottlesQuantity_'+bottlesQuantityItem.idStr" v-model="productionLogisticsStep.bottlesQuantity" type="radio" :value="bottlesQuantityItem.value">
                            <span>{{ bottlesQuantityItem.name }}</span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </i>
                        </label>
                    </div>
                </div>
                <span v-if="validation.errors.bottlesQuantity" class="form__error-text">{{ validation.errors.bottlesQuantity }}</span>
            </div>

            <!-- 2. Container type -->
            <div class="form__pre-group">
                <div class="util--flex">
                    <h4>{{ trans.step.container.title }}</h4>
                    <tooltip v-if="trans.step.container.tooltip.isEnable" :title="trans.step.container.tooltip.title" :content="trans.step.container.tooltip.description"/>
                </div>
            </div>

            <!-- 2a. Filter: materials -->
            <div v-if="availableMaterials.length > 0">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <span>{{ trans.step.container.labelMaterials }}</span>
                    </div> 
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.selectedMaterial}">
                    <div class="form__selectable">
                        <div v-for="(material, index) in availableMaterials" :key="index" class="form__selectable-item">
                            <label :for="'material_'+index">
                                <input :id="'material_'+index" v-model="selectedMaterial" type="radio" :value="material">
                                <span>{{ material }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.selectedMaterial" class="form__error-text">{{ validation.errors.selectedMaterial }}</span>
                </div>
            </div>

            <!-- 2b. Filter: format -->
            <div v-if="availableFormats.length > 0">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <span>{{ trans.step.container.labelFormat }}</span>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.selectedFormat}">
                    <div class="form__selectable">
                        <div v-for="(format, index) in availableFormats" :key="index" class="form__selectable-item">
                            <label :for="'format_'+index">
                                <input :id="'format_'+index" v-model="selectedFormat" type="radio" :value="format">
                                <span>{{ format }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512">
                                        <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                                    </svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.selectedFormat" class="form__error-text">{{ validation.errors.selectedFormat }}</span>
                </div>
            </div>

            <!-- 2c. Filter: colors -->
            <div v-if="availableColors.length > 0">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <span>{{ trans.step.container.labelColors }}</span>
                    </div> 
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.selectedColor}">
                    <div class="form__selectable">
                        <div v-for="(color, index) in availableColors" :key="index" class="form__selectable-item">
                            <label :for="'color_'+index">
                                <input :id="'color_'+index" v-model="selectedColor" type="radio" :value="color">
                                <span>{{ color }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.selectedColor" class="form__error-text">{{ validation.errors.selectedColor }}</span>
                </div>
            </div>

            <!-- Available container types -->
            <div v-if="filteredContainers.length > 0">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <span>{{ trans.step.container.labelContainerChoice }}</span>
                    </div> 
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.containerType}">
                    <div class="form__selectable">
                        <div v-for="container in filteredContainers" :key="container.id" class="form__selectable-item">
                            <label :for="'container_'+container.id">
                                <input :id="'container_'+container.id" v-model="productionLogisticsStep.containerType" type="radio" :value="container">
                                <span>
                                    <img v-if="container.imageUrl" :src="container.imageUrl"/>
                                    {{ container.name }}

                                    <div v-for="datasheet in container.dataSheets">
                                        <a v-if="datasheet.url" :href="datasheet.url">{{ datasheet.name }}</a>
                                    </div>
                                </span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512">
                                        <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 
                                        256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 
                                        0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 
                                        32.8-12.5 45.3 0z"/>
                                    </svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.containerType" class="form__error-text">{{ validation.errors.containerType }}</span>
                </div>
            </div>

            <!-- Available cap types -->
            <div v-if="selectedContainerCaps.length > 0">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <span>{{ trans.step.container.labelCapChoice }}</span>
                    </div> 
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.capType}">
                    <div class="form__selectable">
                        <div v-for="cap in selectedContainerCaps" :key="cap.id" class="form__selectable-item">
                            <label :for="'cap_'+cap.id">
                                <input :id="'cap_'+cap.id" v-model="productionLogisticsStep.capType" type="radio" :value="cap">
                                <span>
                                    <img v-if="cap.imageUrl" :src="cap.imageUrl"/>
                                    {{ cap.name }}
                                    <div v-for="datasheet in cap.dataSheets">
                                        <a v-if="datasheet.url" :href="datasheet.url">{{ datasheet.name }}</a>
                                    </div>
                                </span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512">
                                        <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 
                                        256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 
                                        0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 
                                        32.8-12.5 45.3 0z"/>
                                    </svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.capType" class="form__error-text">{{ validation.errors.capType }}</span>
                </div>
            </div>

            <!-- Available boxes types -->
            <div v-if="selectedContainerBoxes.length > 0">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <span>{{ trans.step.container.labelBoxChoice }}</span>
                    </div> 
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.boxType}">
                    <div class="form__selectable">
                        <div v-for="box in selectedContainerBoxes" :key="box.id" class="form__selectable-item">
                            <label :for="'box_'+box.id">
                                <input :id="'box_'+box.id" v-model="productionLogisticsStep.boxType" type="radio" :value="box">
                                <span>
                                    <img v-if="box.imageUrl" :src="box.imageUrl"/>
                                    {{ box.name }}
                                    <div v-for="datasheet in box.dataSheets">
                                        <a v-if="datasheet.url" :href="datasheet.url">{{ datasheet.name }}</a>
                                    </div>
                                </span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512">
                                        <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 
                                        256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 
                                        0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 
                                        32.8-12.5 45.3 0z"/>
                                    </svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.boxType" class="form__error-text">{{ validation.errors.boxType }}</span>
                </div>
            </div>

            <!-- 3. Storage raw material -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.materialStorage.title }}</h4>
                        <tooltip v-if="trans.step.materialStorage.tooltip.isEnable" :title="trans.step.materialStorage.tooltip.title" :content="trans.step.materialStorage.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.needStorageRawMaterial.value }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="needStorageRawMaterial_yes">
                                <input v-model="productionLogisticsStep.needStorageRawMaterial.value" id="needStorageRawMaterial_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="needStorageRawMaterial_no">
                                <input v-model="productionLogisticsStep.needStorageRawMaterial.value" id="needStorageRawMaterial_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.needStorageRawMaterial.value" class="form__error-text">{{ validation.errors.needStorageRawMaterial.value }}</span>
                </div>
            </div>
            <div v-if="productionLogisticsStep.needStorageRawMaterial.value === true" class="form__group" :class="{'form__error': validation.errors.needStorageRawMaterial.temperatureType }">
                <div class="form__pre-group form__pre-group__info">{{ trans.step.materialStorage.labelTemperature }}</div>
                <!-- Available types -->
                <div class="form__selectable">
                    <!-- Ambient -->
                    <div v-for="(temperatureType, i) in temperatureTypes" :key="i" class="form__selectable-item">
                        <label :for="'temperatureType_'+i">
                            <input :id="'temperatureType_'+i" v-model="productionLogisticsStep.needStorageRawMaterial.temperatureType" type="radio" :value="temperatureType">
                            <span>{{ temperatureType }}</span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </i>
                        </label>
                    </div>
                </div>
                <span v-if="validation.errors.needStorageRawMaterial.temperatureType" class="form__error-text">{{ validation.errors.needStorageRawMaterial.temperatureType }}</span>
            </div>

            <!-- 4. Storage final product -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.productStorage.title }}</h4>
                        <tooltip v-if="trans.step.productStorage.tooltip.isEnable" :title="trans.step.productStorage.tooltip.title" :content="trans.step.productStorage.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.needStorageFinalProduct.value }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="needStorageFinalProduct_yes">
                                <input v-model="productionLogisticsStep.needStorageFinalProduct.value" id="needStorageFinalProduct_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="needStorageFinalProduct_no">
                                <input v-model="productionLogisticsStep.needStorageFinalProduct.value" id="needStorageFinalProduct_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.needStorageFinalProduct.value" class="form__error-text">{{ validation.errors.needStorageFinalProduct.value }}</span>
                </div>
            </div>
            <div v-if="productionLogisticsStep.needStorageFinalProduct.value === true" class="form__group" :class="{'form__error': validation.errors.needStorageFinalProduct.temperatureType }">
                <div class="form__pre-group form__pre-group__info">{{ trans.step.productStorage.labelTemperature }}</div>
                <!-- Available types -->
                <div class="form__selectable">
                    <div v-for="(temperatureType, i) in temperatureTypes" :key="i" class="form__selectable-item">
                        <label :for="'temperatureType2_'+i">
                            <input :id="'temperatureType2_'+i" v-model="productionLogisticsStep.needStorageFinalProduct.temperatureType" type="radio" :value="temperatureType">
                            <span>{{ temperatureType }}</span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </i>
                        </label>
                    </div>
                </div>
                <span v-if="validation.errors.needStorageFinalProduct.temperatureType" class="form__error-text">{{ validation.errors.needStorageFinalProduct.temperatureType }}</span>
            </div>

            <!-- 5. Transportation partner -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.transport.title }}</h4>
                        <tooltip v-if="trans.step.transport.tooltip.isEnable" :title="trans.step.transport.tooltip.title" :content="trans.step.transport.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.needTransportationPartner }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="needTransportationPartner_yes">
                                <input v-model="productionLogisticsStep.needTransportationPartner" id="needTransportationPartner_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="needTransportationPartner_no">
                                <input v-model="productionLogisticsStep.needTransportationPartner" id="needTransportationPartner_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.needTransportationPartner" class="form__error-text">{{ validation.errors.needTransportationPartner }}</span>
                </div>
            </div>

            <!-- 6. Nutritional value -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.nutrition.title }}</h4>
                        <tooltip v-if="trans.step.nutrition.tooltip.isEnable" :title="trans.step.nutrition.tooltip.title" :content="trans.step.nutrition.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.needNutritionalValue }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="needNutritionalValue_yes">
                                <input v-model="productionLogisticsStep.needNutritionalValue" id="needNutritionalValue_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="needNutritionalValue_no">
                                <input v-model="productionLogisticsStep.needNutritionalValue" id="needNutritionalValue_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.needNutritionalValue" class="form__error-text">{{ validation.errors.needNutritionalValue }}</span>
                </div>
            </div>
        </div>

        <step-footer @nextClicked="validate"/>
    </div>
</template>

<script>
import StepFooter from "@/create-my-drink/components/StepFooter";
import StepHeader from "@/create-my-drink/components/StepHeader";
import {AVAILABLE_BOTTLES_QUANTITY, initStepComponent, validateStep} from "@/create-my-drink/lib";
import * as Yup from "yup";
import Tooltip from "@/create-my-drink/components/Tooltip";

export default {
    name: "ProductionLogistics",
    components: {StepHeader, StepFooter, Tooltip},
    data() {
        return {
            productionLogisticsStep: this.$store.state.form.steps.productionLogistics,
            trans: {
                step: this.$store.state.umbracoProps.productionLogistics,
                validation: this.$store.state.umbracoProps.others.validation,
                yes: this.$store.state.umbracoProps.others.yes,
                no: this.$store.state.umbracoProps.others.no,
            },
            aboutDrinkStep: this.$store.state.form.steps.aboutDrink,
            availableBottlesQuantity: AVAILABLE_BOTTLES_QUANTITY,
            availableContainers: this.$store.state.umbracoProps.containers,
            selectedMaterial: null,
            selectedFormat: null,
            selectedColor: null,
            temperatureTypes: [
                this.$store.state.umbracoProps.productionLogistics.materialStorage.temperatureAmbient,
                this.$store.state.umbracoProps.productionLogistics.materialStorage.temperatureCold,
                this.$store.state.umbracoProps.productionLogistics.materialStorage.temperatureFreezing,
            ],
            // Properties must have exactly the same name as the value in the form step storage
            validation: {
                validationSchema: null, // Must be generate in mounted() because of data dependencies 
                errors: {
                    bottlesQuantity: null,
                    containerType: null,
                    capType: null,
                    boxType: null,
                    needStorageRawMaterial: {
                        value: null,
                        temperatureType: null
                    },
                    needStorageFinalProduct: {
                        value: null,
                        temperatureType: null
                    },
                    needTransportationPartner: null,
                    needNutritionalValue: null
                }
            },
        }
    },
    computed: {
        availableMaterials() {
            const materialsSet = new Set();
            this.availableContainers.forEach(container => {
                if (this.aboutDrinkStep.isGaseous === container.containGas) {
                    if (container.materials && container.materials.length > 0) {
                        container.materials.forEach(material => materialsSet.add(material));
                    }
                }
            });
            const materialsArray = Array.from(materialsSet);
            if (materialsArray.length === 1) {
                this.selectedMaterial = materialsArray[0];
            }
            return materialsArray;
        },
        availableFormats() {
            if (!this.selectedMaterial) {
                return [];
            }
            const formatsSet = new Set();
            this.availableContainers.forEach(container => {
                if (container.materials && container.materials.includes(this.selectedMaterial) && this.aboutDrinkStep.isGaseous === container.containGas) {
                    if (container.format) {
                        formatsSet.add(container.format);
                    }
                }
            });
            const formatsArray = Array.from(formatsSet);
            if(formatsArray.length == 1 && this.selectedFormat != formatsArray[0])
            {
                this.$nextTick(() => {
                    this.selectedFormat = formatsArray[0];
                });
            }
            return formatsArray;
        },
        availableColors() {
            if (!this.selectedMaterial || !this.selectedFormat) {
                return [];
            }
            const colorsSet = new Set();
            this.availableContainers.forEach(container => {
                if (container.materials && container.materials.includes(this.selectedMaterial) && 
                    container.format === this.selectedFormat && 
                    this.aboutDrinkStep.isGaseous === container.containGas) {
                    if (container.colors && container.colors.length > 0) {
                        container.colors.forEach(color => colorsSet.add(color));
                    }
                }
            });
            const colorsArray = Array.from(colorsSet);
            if(colorsArray.length == 1 && this.selectedColor != colorsArray[0])
            {
                this.$nextTick(() => {
                    this.selectedColor = colorsArray[0];
                });
            }
            return colorsArray;
        },
        filteredContainers() {
            if (!this.selectedMaterial || !this.selectedFormat || !this.selectedColor) {
                return [];
            }
            const containers = this.availableContainers.filter(container => {
                return container.materials && container.materials.includes(this.selectedMaterial) &&
                    container.colors && container.colors.includes(this.selectedColor) &&
                    container.format === this.selectedFormat &&
                    this.aboutDrinkStep.isGaseous === container.containGas;
            });

            if(containers.length == 1 && this.productionLogisticsStep.containerType != containers[0])
            {
                this.$nextTick(() => {
                    this.productionLogisticsStep.containerType = containers[0];
                });
            }
            return containers;
        },
        selectedContainerCaps() {
            if (this.productionLogisticsStep.containerType) {
                if(this.productionLogisticsStep.containerType.caps.length == 1)
                {
                    this.$nextTick(() => {
                        this.productionLogisticsStep.capType = this.productionLogisticsStep.containerType.caps[0];
                    });
                }             
                return this.productionLogisticsStep.containerType.caps;
            }
            return [];
        },
        selectedContainerBoxes() {
            if (this.productionLogisticsStep.containerType && this.productionLogisticsStep.capType) {
                if(this.productionLogisticsStep.containerType.boxes.length == 1)
                {
                    this.$nextTick(() => {
                        this.productionLogisticsStep.boxType = this.productionLogisticsStep.containerType.boxes[0];
                    });
                }             
                return this.productionLogisticsStep.containerType.boxes;
            }
            return [];
        }
    },
    watch: {
        selectedMaterial(newVal, oldVal) {
            this.selectedFormat = null;
            this.selectedColor = null;
            this.productionLogisticsStep.containerType = null;
            this.productionLogisticsStep.capType = null;
        },
        selectedFormat(newVal, oldVal) {
            this.selectedColor = null;
            this.productionLogisticsStep.containerType = null;
            this.productionLogisticsStep.capType = null;
        },
        selectedColor(newVal, oldVal) {
            this.productionLogisticsStep.containerType = null;
            this.productionLogisticsStep.capType = null;
        }
    },
    mounted() {
        window.scrollTo({
            top: 0
        })
        
        // generate the validation schema that have some data() dependencies 
        this.validation.validationSchema = Yup.object().shape({
            bottlesQuantity: Yup.number().nullable()
                .required(this.trans.validation.required)
                .oneOf(this.availableBottlesQuantity.map(d => d.value), this.trans.validation.required),
            containerType: Yup.object().nullable()
                .required(this.trans.validation.required),
            capType: Yup.object().nullable()
                .required(this.trans.validation.required),
            boxType: Yup.object().nullable()
                .required(this.trans.validation.required),
            needStorageRawMaterial: Yup.object().shape({
                value: Yup.boolean().nullable()
                    .required(this.trans.validation.required),
                temperatureType: Yup.string().nullable().when("value", {
                    is: true,
                    then: Yup.string().nullable()
                        .required(this.trans.validation.required)
                        .oneOf(this.temperatureTypes, this.trans.validation.required),
                }),
            }),
            needStorageFinalProduct: Yup.object().shape({
                value: Yup.boolean().nullable()
                    .required(this.trans.validation.required),
                temperatureType: Yup.string().nullable().when("value", {
                    is: true,
                    then: Yup.string().nullable()
                        .required(this.trans.validation.required)
                        .oneOf(this.temperatureTypes, this.trans.validation.required),
                }),
            }),
            needTransportationPartner: Yup.boolean().nullable()
                .required(this.trans.validation.required),
            needNutritionalValue: Yup.boolean().nullable()
                .required(this.trans.validation.required),
        })
    },
    methods: {
        validate: function() {
            validateStep(this.validation.validationSchema, this.productionLogisticsStep, this.validation.errors, () => {
                // Store + persist data and pass to the next step at the same time
                this.$store.commit('nextStep')
            })
        }
    }
}
</script>

<style scoped>

</style>